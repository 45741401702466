import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import DfContent from "@/vue/domain/content/df-content";
import Utils from "@/vue/infrastructure/utils/helpers";
import { CONTAINER_TYPES } from "@/vue/infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";

@Component({
  components: {
    DfLoadingMask: () => import(/* webpackChunkName: 'df-loading-mask' */ "@/vue/components/df-loading-mask/df-loading-mask.vue"),
    DfPdfViewer: () => import(/* webpackChunkName: 'df-pdf-viewer' */ "@/vue/components/df-pdf-viewer/df-pdf-viewer.vue"),
  },
})
export default class DfSectionDigitalPublicationComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  @Prop({ type: String, default: "" })
  position!: string;

  @Prop({ type: Boolean, default: false })
  fullscreenViewer!: boolean;

  @Prop({ type: Boolean, default: false })
  onlyPdf!: boolean;

  private downloading = false;

  get promotionContents(): Array<DfContent> {
    return this.$store.getters.promotionContents;
  }

  get digitalPublications(): Array<DfContent> {
    return this.promotionContents.filter((content: DfContent) => content.type.code === "FLY");
  }

  get digitalPublicationByPosition(): DfContent {
    return this.digitalPublications.find((digitalPublication: DfContent) => {
      const value: any = Utils.getPropertyValue(digitalPublication, Utils.PROPERTY_POSITION, "LIST");
      return value ? value.key === "ALL" || value.key === this.position : false;
    });
  }

  get pdfUrl(): string {
    const pdfData: any = this.digitalPublicationByPosition ? Utils.getPropertyValue(this.digitalPublicationByPosition, Utils.PROPERTY_PDF, "FILE") : null;
    return pdfData ? `${Utils.getEndpointContextPath()}/files/${pdfData.uniqueId}/${pdfData.basename}.${pdfData.extension}` : null;
  }

  @Watch("pdfUrl", { immediate: true })
  private changeVisibility() {
    this.$emit("setVisibility", this.pdfUrl !== null);
  }

  private downloadFlyer() {
    const pdfData: any = this.digitalPublicationByPosition ? Utils.getPropertyValue(this.digitalPublicationByPosition, Utils.PROPERTY_PDF, "FILE") : null;

    if (pdfData) {
      this.downloading = true;
      this.service
        .downloadFile({ fileUniqueId: pdfData.uniqueId, fileName: pdfData.name })
        .then((file: any) => {
          this.downloading = false;
          Utils.downloadFile(file, `${pdfData.basename}.${pdfData.extension}`, Utils.getMimeFromExtension(pdfData.extension));
        })
        .catch((error: Error) => {
          this.downloading = false;
          Utils.defaultApiErrorHandler(error, this);
        });
    }
  }
}
